import { Col, Row, Typography, Radio, Button, Input } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import config from '../../config.json';
import { useNavigate } from 'react-router';
import { getContracts } from '../../services/contractService';
import { normalized } from '../../utils/string';
import StyledRadio from '../../components/styledRadio';
import auth from "../../services/authService";
import ServicesTable from './ServicesTable';

const { Title } = Typography;
const { statusContract } = config;

const PAGE_SIZE = 4000;

const STATUS_STYLES = [
	{
		color: '#FF8C00',
		text: 'Todos',
		status: '',
		rol: 'SERVICE_FILTER_ALL'
	},
	{
		color: '#69B5EC',
		text: 'Solicitudes',
		status: statusContract.solicitado,
		rol: 'SERVICE_FILTER_SOL'
	},
	{
		color: '#228B22',
		text: 'Instalado',
		status: statusContract.instalado,
		rol: 'SERVICE_FILTER_INS'
	},
	{
		color: '#a0d911',
		text: 'Instalación Aprobada',
		status: statusContract.instalacionAprobada,
		rol: 'SERVICE_FILTER_IAP'
	},
	{
		color: '#fa541c',
		text: 'Instalación Rechazada',
		status: statusContract.instalacionRechazada,
		rol: 'SERVICE_FILTER_IRE'
	},
	{
		color: '#36EF80',
		text: 'Activo',
		status: statusContract.activo,
		rol: 'SERVICE_FILTER_ACT'
	},
	{
		color: '#E8D850',
		text: 'Suspendido',
		status: statusContract.suspendido,
		rol: 'SERVICE_FILTER_SUS'
	},
	{
		color: '#EC2A2A',
		text: 'Cancelado',
		status: statusContract.cancelado,
		rol: 'SERVICE_FILTER_CAN'
	}
];

export default function Services() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [contracts, setContracts] = useState([]);
	const [search, setSearch] = useState("");
	const [activeStatus, setActiveStatus] = useState(
		auth.isInRole("SERVICE_FILTER_SOL") ? statusContract.solicitado : null);

	useEffect(() => {
		setLoading(true);

		// Empty statusId parameter will get all contracts
		getContracts("", PAGE_SIZE, 0)
			.then(({ data }) => {
				setContracts(data)
			})
			.finally(() => setLoading(false));
	}, []);

	const dataTable = useMemo(() => {
		const normSearch = normalized(search);
		
		return contracts.filter(c => 
			activeStatus && c.contractStatusId === activeStatus &&
			(!search || 
				normalized(c.clientName).includes(normSearch) ||
				normalized(c.clientLastName).includes(normSearch) ||
				normalized(c.clientMotherLastName ?? "").includes(normSearch) ||
				normalized(c.contractId.toString()).includes(normSearch) ||
				normalized(c.street).includes(normSearch) ||
				normalized(c.productName).includes(normSearch))
		);
	}, [contracts, activeStatus, search]);

	return <>
		<Row justify='space-between' style={{ margin: '2rem' }}>
			<Col span={4}>
				<Title level={2}>Servicios</Title>
			</Col>
			<Col span={4}>
				<Button
					type='primary'
					size='large'
					icon={<FaPlusCircle />}
					onClick={() => navigate('/intelinet/servicios/contrato')}
					disabled={!auth.isInRole("SERVICE_CREATE")}>
					Nuevo
				</Button>
			</Col>
		</Row>
		<Row>
			<Radio.Group
				value={activeStatus}
				style={{ margin: '2rem', width: '100%' }}
				onChange={({ target }) => setActiveStatus(target.value)}>
				<Row gutter={16} justify='space-between'>
					{STATUS_STYLES
						.filter(s => auth.isInRole(s.rol))
						.map(({ rol, ...rest }) => <Col key={rest.status}>
							<StyledRadio {...rest} disabled={loading} />
						</Col>)}
				</Row>
			</Radio.Group>
		</Row>
		<Row>
			<Col span={6} style={{ marginLeft: '2rem', marginBottom: '1rem' }}>
				<Input placeholder='Buscar' value={search} onChange={({ target }) => setSearch(target.value)} />
			</Col>
		</Row>
		<Row style={{ margin: '2rem' }}>
			<Col span={24}>
				<ServicesTable dataSource={dataTable} loading={loading} />
			</Col>
		</Row>
	</>;
}
