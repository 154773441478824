import React, {useState} from 'react';
import { Form, Input } from 'antd';

const StringItem = (props) => {
    const [value, setValue] = useState(props.text);

    const handleTakeValue = () =>{
        props.datason({'id': props.characteristicId, 'valor': value}, false);
    }

    return <Form.Item name={props.name} label={props.label} rules={[{ required: props.required }]} initialValue={props.text}>
        <Input
            value={value}
            onChange={e => setValue(e.target.value)}
            onBlur={handleTakeValue}
            maxLength={255}
            showCount />
    </Form.Item>
}

export default StringItem;