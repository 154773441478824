
import {  Skeleton, Tabs } from 'antd';
import React, { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import config from '../../config.json';
import CharacteristicsForm from '../../components/common/CharacteristicsForm';
import Container from '../../components/common/Container';
import ContractForm from './contractForm';
import useContractCharacteristics from './../../hooks/useContractCharacteristics';
import useCharacteristicResults from '../../hooks/useCharacteristicResults';
import { getContract } from '../../services/contractService';
import { groupBy } from './../../utils/array';

const objectId = (contractId) => `Contract|${contractId}`

function Contracts() {
    const { contractId } = useParams();
    const navigate = useNavigate();
    const [contract, setContract] = useState(null);
    const { data: characteristics, isLoading: isLoadingCharacteristics } = contractId ? useContractCharacteristics(contractId) : [];
    const { data: results, isLoading: isLoadingCharacteristicsResults } = contractId ? useCharacteristicResults(objectId(contractId)) : [];

    const fetchContract = async (clientContractId) => {
        try {
            const contractResult = await getContract(clientContractId);
            setContract(contractResult.data);
            return contractResult.data;
        } catch (error) {
            if (error.response && error.response.status === 404)
                navigate(`/intelinet/servicios/contrato-not-found`);
        } 
    }

    const characteristicsTabs = () => {
        if(!characteristics) return [];

        return Object.entries(groupBy(characteristics, "classId"))
            .map(([key, value]) => {
                const first = value[0];
                
                return {
                    key,
                    label: first.className,
                    children: <CharacteristicsForm
                        objectId={objectId(contractId)}
                        items={value}
                        datos={results}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 8 }}
                        submitBtnWrapperCol={{ offset: 4, span: 16 }} 
                        disabled={contract && (![config.statusContract.solicitado, config.statusContract.instalacionRechazada].some(s => s === contract.contractStatusId))}
                        headerTextMessage="Contrato"/>
                }
            });
    }

    return <Container title="Contrato">
        <Skeleton active loading={isLoadingCharacteristics || isLoadingCharacteristicsResults}>
            <Tabs items={[{
                key: "1",
                label: "Datos generales",
                children: <ContractForm  contractId={contractId} fetchContract={fetchContract} />
            }, ...characteristicsTabs()]} />
        </Skeleton>
        
    </Container>
}

export default Contracts;