import { Table, Drawer } from 'antd';
import moment from 'moment';
import React from 'react';
import config from '../../config.json';
import { CloseOutlined } from '@ant-design/icons';

const { dateTimeFormat } = config;

export default function StatusHistoryDrawer({ history, open, close }) {

	const data = React.useMemo(() => {
		if (!history) return [];
		return history.map((status, index) => ({
			key: index,
			date: status.creationDate,
			status: status.contractStatusName,
			user: status.userName,
			reason: status.comments,
		})).sort((left, right) => new Date(right.date) - new Date(left.date));
	}, [history]);

	const columns = [
		{
			title: 'Fecha',
			dataIndex: 'date',
			render: value => moment(value).format(dateTimeFormat)
		},
		{
			title: 'Estatus',
			dataIndex: 'status',
		},
		{
			title: 'Usuario',
			dataIndex: 'user',
		},
		{
			title: 'Motivo',
			dataIndex: 'reason',
		}
	];
	
	return (
		<Drawer title="Historial de Estatus" placement="right" onClose={close} closeIcon={<CloseOutlined style={{ color: 'white' }} />}
			open={open} headerStyle={{backgroundColor: '#4597bd'}}>
			<Table
				dataSource={data}
				columns={columns}
				pagination={false}
				size='small'
			/>
		</Drawer>

	);
}
