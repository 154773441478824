import http from './httpService';
import config from '../config.json'
import jwtDecode from "jwt-decode";

http.setAuthorization(localStorage.getItem("token"));
http.setDefaultHeaders(config.customerId, config.organizationId);

export function getUsers() {
	return http.get('/Auth');
}

export async function login(username, password) {
	return http.post(`/Auth/Login`, { username, password });
}

export function createUser(user) {
	return http.post('/Auth/Register', user);
}

export function changePassword(values) {
	return http.put('/Auth', values);
}

export function getRoles() {
	return http.get('/Auth/Roles');
}

export function setRoles(userId, roles) {
	return http.put('/Auth/Roles', roles);
}

export function getCurrentUser() {
	const jwt = localStorage.getItem("token");
	return jwt ? jwtDecode(jwt) : null;
}

export function isInRoles(roles) {
	if (!roles) return false;
	const user = getCurrentUser();
	if (!user) return false;
	let dataRoles = user.role;
	if (dataRoles.constructor !== Array)
		dataRoles = [dataRoles];
	return dataRoles.some(role => roles.includes(role));
}

export function isInRole(role) {
	const user = getCurrentUser();

	if(typeof role !== "string")
		throw new Error("Role param is not a single string.");

	return user && role && user.role.some(r => r === role);
}

export default {
	getUsers,
	changePassword,
	getRoles,
	setRoles,
	isInRole,
	isInRoles,
	getCurrentUser
};
