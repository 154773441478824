import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { DatePicker, Form, Input, Modal, Select, notification } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import 'moment/locale/es-mx';

import config from '../../config.json';
import { toISO } from '../../utils/dateFormat';
import useAddSuspension from './../../hooks/useAddSuspension';
import useContractStatusTypes from './../../hooks/useContractStatusTypes';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const SuspensionModal = ({ contractId, open, onCancel, onOk }) => {
    const queryClient = useQueryClient();
	const [endDateRequired, setEndDateRequired] = useState(null);

    const [form] = Form.useForm();
    const { data: types, isLoading, error } = useContractStatusTypes(config.statusContract.suspendido);
    
    const addSuspension = useAddSuspension({
		onSuccess: () => {
			form.resetFields();
			setEndDateRequired(null);
			onOk();
		},
		onError: (error) => {
            if(error.response && error.response.status === 400)
                notification.warning({ description: error.response.data });
        }
	});

    const disabledDate = current => current.isBefore(moment().add(1, 'days'), 'days');

    const handleContractStatusTypeChange = value => {
		const contractStatusType = types.find(s => s.id === value);
		setEndDateRequired(contractStatusType.endDateRequired);
	};

	const handleOk = () => {
        form.validateFields()
            .then((values) => {
                // From dates selected removes time part, convert to Date instance
                // and formats to ISO without time zone
                let startDate = null;
                let endDate = null;

                if (endDateRequired) {
                    startDate = toISO(values.range[0].startOf('day').toDate());
                    endDate = toISO(values.range[1].startOf('day').toDate());
                } else 
                    startDate = toISO(values.date.startOf('day').toDate());

                addSuspension.mutate({
                    contractId,
                    startDate,
                    endDate,
                    comments: values.comments,
                    contractStatusTypeId: values.contractStatusTypeId
                });
            })
            .catch(() => { /* DO NOTHING ON ERROR */ });
	};

    const validateRange = (_, value) => {
        // Current suspensions
        const suspensions = queryClient.getQueryData(['suspensions', contractId]);
        
        // If is single startDate, adds max date as endDate
        if(!Array.isArray(value)) {
            const maxDate = moment(new Date(8640000000000000)); // Max Date in JS
            value = [value, maxDate]; 
        }

        return suspensions.some(s => {
            // Add a day to include the whole endDate in the range
            const end = moment(s.endDate).add(1, 'days').toDate();
            
            return value[0].toDate() <= new Date(end) &&
                new Date(s.startDate) <= value[1].toDate()
        })
                ? Promise.reject(new Error('¡Ya existe un suspensión dentro de ese rango de fechas!'))
                : Promise.resolve();
    }

    return <Modal
        open={open}
        title='Suspender contrato'
        centered
        width={720}
        okText="Guardar"
        confirmLoading={addSuspension.isLoading}
        closable={false}
        maskClosable={false}
        onOk={handleOk}
        cancelButtonProps={{ disabled: addSuspension.isLoading }}
        onCancel={onCancel}>
        <Form form={form}>
            <Form.Item
                name='contractStatusTypeId'
                label='Tipo de suspensión'
                rules={[{ required: true, message: '¡Selecciona un tipo de suspensión!' }]}>
                <Select
                    status={error && 'error'}
                    loading={isLoading}
                    placeholder='Tipo de suspensión'
                    options={types && types.map(t => ({ label: t.name, value: t.id }))}
                    onChange={handleContractStatusTypeChange}
                />
            </Form.Item>
            {endDateRequired ? <Form.Item
                name='range'
                rules={[
                    { required: true, message: '¡Selecciona un rango de fechas de suspensión!' },
                    { validator: validateRange }
                ]}
                label='Rango de fechas de la suspensión'>
                <RangePicker locale={locale} disabledDate={disabledDate} />
            </Form.Item> : <Form.Item
                name='date'
                rules={[
                    { required: true, message: '¡Selecciona una fecha de suspensión!' },
                    { validator: validateRange }
                ]}
                label='Fecha de la suspensión'>
                <DatePicker locale={locale} disabledDate={disabledDate} />
            </Form.Item>}
            <Form.Item name='comments' label='Comentarios'>
                <TextArea rows={4} placeholder='Suspensión temporal por pago atrasado' />
            </Form.Item>
        </Form>
    </Modal>
}

export default SuspensionModal;