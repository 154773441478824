import React, { useState } from 'react'
import { notification, Form, Skeleton, Tabs } from 'antd';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

import config from '../../config.json';
import useCustomer from '../../hooks/useCustomer';
import CharacteristicsForm from '../../components/common/CharacteristicsForm';
import Container from '../../components/common/Container';
import CustomerForm from './CustomerForm';
import { updateCustomer } from './../../services/customerService';
import { groupBy } from './../../utils/array';
import { nullifyEmptyStrings } from './../../utils/object';
import useClientCharacteristics from './../../hooks/useClientCharacteristics';
import useCharacteristicResults from '../../hooks/useCharacteristicResults';

const objectId = (clientId) => `Client|${clientId}`

const Customer = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [form] = Form.useForm();
    const { data, isLoading, error } = useCustomer(id);
    const [loading, setLoading] = useState(false);
    const { data: characteristics, isLoading: isLoadingCharacteristics } = useClientCharacteristics(config.clientGroups.general);
    const { data: results, isLoading: isLoadingCharacteristicsResults } = useCharacteristicResults(objectId(id));

    // Handle Not Found error
    if(error && error.response && error.response.status === 404)
        return navigate("*");

    const customerData = ({
        ...data,
        birthDate: data && data.birthDate && moment(data.birthDate)
    });

    const handleFinish = async (values) => {
        setLoading(true);

        // Nullify empty strings
        nullifyEmptyStrings(values);

        const request = {
            ...values,
            birthDate: values.birthDate && values.birthDate.format('YYYY-MM-DD'),
            address: { ...values.address, id: customerData.address.id }
        }

        try {
            await updateCustomer(customerData.id, request);
            notification.success({ message: 'Clientes', description: 'Se actualizó el cliente.' });
        } finally {
            setLoading(false);
        }
    }

    const characteristicsTabs = () => {
        if(!characteristics) return [];

        return Object.entries(groupBy(characteristics, "classId"))
            .map(([key, value]) => {
                const first = value[0];
                
                return {
                    key,
                    label: first.className,
                    children: <CharacteristicsForm
                        objectId={objectId(id)}
                        items={value}
                        datos={results}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        submitBtnWrapperCol={{ offset: 8, span: 16 }} 
                        headerTextMessage="Clientes"/>
                }
            });
    }

    return <Container title="Cliente">
        {isLoading || isLoadingCharacteristics || isLoadingCharacteristicsResults && <Skeleton active />}
        {data && characteristics && <Tabs items={[{
            key: "1",
            label: "Datos generales",
            children: <CustomerForm form={form} btnLoading={loading} initialValues={customerData} onFinish={handleFinish}/>
        }, ...characteristicsTabs()]} />}
    </Container>
}               

export default Customer;