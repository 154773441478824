import { Button, Table, Tooltip } from "antd";
import moment from "moment";
import { FaPencilAlt } from "react-icons/fa";
import React from "react";
import { useNavigate } from "react-router";
import config from '../../config.json';
import auth from "../../services/authService";

const EditColumn = ({ contractId }) => {
    const navigate = useNavigate();

    return <Tooltip title="Editar">
        <Button
            type="primary"
            onClick={() => navigate(`/intelinet/servicios/contrato/${contractId}`)}
            icon={<FaPencilAlt />}
            shape="circle"
            disabled={!auth.isInRoles("SERVICE_UPDATE")}
        />
    </Tooltip>
}

const COLUMNS = [
    { title: "Folio servicio", dataIndex: "contractId" },
    {
        title: "Cliente",
        render: (_, row) => `${row.clientName} ${row.clientLastName}${row.clientMotherLastName ? ' ' + row.clientMotherLastName : ''}`
    },
    {
        title: "Domicilio",
        render: (_, row) => `${row.street} ${row.numberExternal}`
    },
    { title: "Plan", dataIndex: "productName" },
    { title: "Estatus", dataIndex: "lastStatusName" },
    { title: "Motivo", dataIndex: "lastStatusComments" },
    { 
        title: "Fecha alta",
        dataIndex: "lastStatusCreationDate",
        render: (value) => value ? moment(value).format(config.dateFormat) : "No disponible"
    },
    { title: "Editar", render: (_, row) => <EditColumn {...row} /> }
];

const ServicesTable = (props) => <Table {...props} rowKey="contractId" columns={COLUMNS} />

export default ServicesTable;