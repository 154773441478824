import { Radio, Row, Col, Spin } from 'antd';
import React, { useEffect, useState } from 'react';

import { getStats } from '../../services/contractService';

export default function StyledRadio({ color, text, status, disabled }) {
	const [loading, setLoading] = useState(false);
	const [count, setCount] = useState(0);

	useEffect(() => {
		getStats(status)
			.then(({ data }) => setCount(data))
			.finally(() => setLoading(false));
	}, []);

	return <Radio.Button
			disabled={disabled}
			value={status}
			size='large'
			style={{
				height: '100px',
				borderRadius: '0.5rem',
				width: '200px',
				margin: '1rem',
			}}>
			<Row justify='center' align='middle'>
				<Col
					span={2}
					style={{
						background: color,
						height: '100px',
						width: '30px',
						borderRadius: '0.5rem',
					}}></Col>
				<Col span={18} offset={2}>
					<div style={{ textAlign: 'center' }}>
						{text}
						<br />
						{loading ? <Spin /> : <span>{count}</span>}
					</div>
				</Col>
			</Row>
		</Radio.Button>;
}
