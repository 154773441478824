import { Button, Form, Select, Input, Row, Col, notification } from 'antd';
import {
	PlusOutlined,
	MinusCircleOutlined,
	CheckCircleTwoTone,
	VerticalAlignBottomOutlined,
	CheckSquareOutlined,
	CloseCircleOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import config from '../../config.json';

import { IP_PATTERN } from '../../utils/regex';

import { getCategoriesProducts } from '../../services/CategoriesService';
import { createServiceOrder } from '../../services/serviceOrderService';
import { getWorkCenters } from '../../services/workCenterService';
import { approveInstallation, installContract } from '../../services/contractService';
import { RejectInstallationModal } from './RejectInstallationModal';
import auth from "../../services/authService";

export default function Equipment({
	contractId,
	contractStatus,
	clientId,
	salesOfficeId,
	setReloadContract,
	productId,
	planCategoryId,
	onActivateContract,
	refreshContract
}) {
	const categoryId = 2;
	const onlyActive = true;
	const customerId = '0073f19e-6666-4907-b93b-eaaaa2c2e885';
	const organizationId = 'Intelinet';
	const [products, setProducts] = useState([]);
	const [workCenters, setWorkCenters] = useState([]);
	const [allWorkCenters, setAllWorkCenters] = useState([]);
	const [radioBases, setRadioBases] = useState([]);
	const [openRejectInstallationModal, setOpenRejectInstallationModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingForm, setLoadingForm] = useState(false);
	const [form] = Form.useForm();
	const stockTypes = [
		{
			label: 'Venta',
			value: 'I',
		},
		{
			label: 'Comodato',
			value: 'C',
		},
	];

	const loadCatalogs = async () => {
		try {
			setLoading(true);
			const [productsResult, workCentersResult] = await Promise.all([
				getCategoriesProducts(
					categoryId,
					onlyActive,
					customerId,
					organizationId
				),
				getWorkCenters(),
			]);

			const products = productsResult.data.products
				.map(product => ({
					value: product.id,
					label: product.id + ' | ' + product.name,
				}))
				.sort((a, b) => a.label.localeCompare(b.label));
			const workCenters = workCentersResult.data;
			const allRadioBasesIds = workCentersResult.data.map(
				workCenter => workCenter.workCenterLocationId
			);
			const radioBasesIds = [...new Set(allRadioBasesIds)];
			const radioBases = radioBasesIds
				.map(radioBaseId => {
					const workCenter = workCenters.find(
						workCenter => workCenter.workCenterLocationId === radioBaseId
					);

					return {
						value: radioBaseId,
						label: workCenter.workCenterLocationName,
					};
				})
				.sort((a, b) => {
					return a.label.localeCompare(b.label);
				});
			setRadioBases(radioBases);
			setAllWorkCenters(workCenters);
			setProducts(products);
		} finally {
			setLoading(false);
		}
	};


	useEffect(() => {
		loadCatalogs();
		form.resetFields();
	}, [contractId]);

	const onOkRejectInstallation = () => {
		setOpenRejectInstallationModal(false);
		refreshContract();
	}

	const handleFilter = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const handleFinish = async values => {
		try {
			setLoadingForm(true);
			const components = values.equipments.map(product => ({
				plantId: '1',
				storageLocationId: '1',
				categoryId,
				productId: product.productId,
				batch: product.lote,
				quantity: product.quantity,
				unitOfMeasureId: 'UN',
				stockType: product.stockTypeId,
			}));
			const contract = {
				contractId,
				categoryId: planCategoryId,
				productId,
				clientId,
				workCenterId: values.workCenterId,
				salesOfficeId,
				includeInstallService: true,
				components,
				reference: values.ip,
			};

			await createServiceOrder(contract, customerId, organizationId);

			notification.success({
				message: 'Sistema',
				description: 'Se ha activado el contrato',
			});

			setReloadContract(true);
			onActivateContract();
		} catch (error) {
			if (error.response && error.response.status === 400)
				notification.warning({ message: 'Activar', description: error.response.data, duration: 7 });
		} finally {
			setLoadingForm(false);
		}
	};

	const handleRadioBaseChange = radioBaseId => {
		form.setFieldValue('workCenterId', null);
		const workCentersRadioBase = allWorkCenters.filter(
			workCenter => workCenter.workCenterLocationId === radioBaseId
		);

		const workCenters = workCentersRadioBase
			.map(x => ({
				value: x.id,
				label: x.name,
			}))
			.sort((a, b) => a.label.localeCompare(b.label));

		setWorkCenters(workCenters);
	};

	const handleInstallContract = async (contractId) => {
		try {
			setLoadingForm(true);
			await installContract(contractId, {comments: null});
			refreshContract();
			notification.success({ message: 'Contrato', description: 'El contrato ha sido instalado satisfactoriamente.' });
		} catch (error) {
			if (error.response && error.response.status === 400) {
				notification.warning({ message: 'Instalar', description: error.response.data });
			}
		} finally {
			setLoadingForm(false);
		}
	}
	
	const handleApproveInstallation = async (contractId) => {
		try {
			setLoadingForm(true);
			await approveInstallation(contractId, {comments: null});
			refreshContract();
			notification.success({ message: 'Contrato', description: 'El contrato ha sido aprobado satisfactoriamente.' });
		} catch (error) {
			if (error.response && error.response.status === 400) {
				notification.warning({ message: 'Aprobar', description: error.response.data });
			}
		} finally {
			setLoadingForm(false);
		}
	}

	return (
		<>
			<RejectInstallationModal
				contractId={contractId}
				open={openRejectInstallationModal}
				onOkRejectInstallation={onOkRejectInstallation}
				onCancel={() => setOpenRejectInstallationModal(false)}
			/>

			<Form form={form} onFinish={handleFinish}>
				<Row>
					<Col span={24}>
						<Form.Item
							name='radioBaseId'
							label='Radio base'
							rules={[
								{
									required: true,
									message: '¡Selecciona una radio base!',
								},
							]}>
							<Select
								showSearch={true}
								style={{ width: '100%' }}
								placeholder='Radio base'
								loading={loading}
								options={radioBases}
								filterOption={handleFilter}
								onChange={handleRadioBaseChange}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							name='workCenterId'
							label='Sector'
							rules={[
								{
									required: true,
									message: '¡Selecciona un sector!',
								},
							]}>
							<Select
								showSearch={true}
								style={{ width: '100%' }}
								placeholder='Sector'
								loading={loading}
								options={workCenters}
								filterOption={handleFilter}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							name='ip'
							label='IP'
							rules={[{
								required: true,
								maxLength: 15,
								message: '¡Inserta una IP!',
								pattern: IP_PATTERN
							}]}>
							<Input placeholder='IP' />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.List name='equipments'>
							{(fields, { add, remove }) => (
								<>
									{fields.map(field => (
										<Row key={field.key} gutter={5}>
											<Col span={6}>
												<Form.Item
													{...field}
													name={[field.name, 'productId']}
													label='Equipo'
													rules={[
														{
															required: true,
															message: '¡Selecciona un equipo!',
														},
													]}>
													<Select
														showSearch={true}
														style={{ width: '100%' }}
														placeholder='Asus AX6000'
														loading={loading}
														options={products}
														filterOption={handleFilter}
													/>
												</Form.Item>
											</Col>
											<Col span={6}>
												<Form.Item
													{...field}
													name={[field.name, 'lote']}
													label='Lote'>
													<Input maxLength={17} />
												</Form.Item>
											</Col>
											<Col span={4}>
												<Form.Item
													{...field}
													name={[field.name, 'quantity']}
													label='Cantidad'
													rules={[
														{
															required: true,
															message: '¡Ingresa una cantidad!',
														},
													]}
													initialValue={1}>
													<Input
														placeholder='¡Ingresa un número!'
														type='number'
														min={1}
													/>
												</Form.Item>
											</Col>
											<Col span={6}>
												<Form.Item
													{...field}
													name={[field.name, 'stockTypeId']}
													label='Tipo de entrega'
													rules={[
														{
															required: true,
															message: '¡Selecciona un tipo de entrega!',
														},
													]}>
													<Select
														showSearch={true}
														style={{ width: '100%' }}
														placeholder='Entrega'
														loading={loading}
														options={stockTypes}
														filterOption={handleFilter}
													/>
												</Form.Item>
											</Col>
											<Col span={1}>
												<MinusCircleOutlined
													onClick={() => remove(field.name)}
												/>
											</Col>
										</Row>
									))}
									{ contractStatus === config.statusContract.instalacionAprobada && (
										<Form.Item>
											<Button
												type='dashed'
												onClick={() => add()}
												block
												icon={<PlusOutlined />}>
												Añadir equipo
											</Button>
										</Form.Item>
									)}
								</>
							)}
						</Form.List>
					</Col>
				</Row>
				<Row gutter={16} justify='end'>
					{ contractStatus === config.statusContract.instalacionAprobada && (auth.isInRole('SERVICE_FILTER_ACT') || auth.isInRole('SERVICE_FILTER_IAP')) && (
						<Col>
							<Form.Item>
								<Button
									type='primary'
									htmlType='submit'
									size='large'
									loading={loadingForm}
									icon={<CheckCircleTwoTone twoToneColor={'#00924c'} />}
									disabled={!auth.isInRole('SERVICE_ACTIVATE')}>
									Activar
								</Button>
							</Form.Item>
						</Col>
					)}
					{[config.statusContract.solicitado, config.statusContract.instalacionRechazada].some(s => s === contractStatus) && (auth.isInRole('SERVICE_FILTER_SOL') || auth.isInRole('SERVICE_FILTER_IRE')) && (
						<Col>
							<Form.Item>
								<Button
									type='primary'
									htmlType='button'
									size='large'
									loading={loadingForm}
									onClick={() => handleInstallContract(contractId)}
									icon={<VerticalAlignBottomOutlined />}>
									Instalar
								</Button>
							</Form.Item>
						</Col>
					)}
					{contractStatus === config.statusContract.instalado && auth.isInRole('SERVICE_FILTER_INS') && (
						<Col>
							<Form.Item>
								<Button
									type='primary'
									htmlType='button'
									size='large'
									loading={loadingForm}
									onClick={() => setOpenRejectInstallationModal(true)}
									icon={<CloseCircleOutlined />}>
									Rechazar
								</Button>
							</Form.Item>
						</Col>
					)}
					{contractStatus === config.statusContract.instalado && auth.isInRole('SERVICE_FILTER_INS') && (
						<Col>
							<Form.Item>
								<Button
									type='primary'
									htmlType='button'
									size='large'
									loading={loadingForm}
									onClick={() => handleApproveInstallation(contractId)}
									icon={<CheckSquareOutlined />}>
									Aprobar
								</Button>
							</Form.Item>
						</Col>
					)}
				</Row>
			</Form>
		</>
	);
}
