import { useQuery } from "@tanstack/react-query";

import { getCharacteristics } from "../services/contractService";

const useContractCharacteristics = (contract) => useQuery({
    queryKey: ['contract', 'characteristics', contract],
    queryFn: () => getCharacteristics(contract).then(res => res.data),
    staleTime: 365 * 24 * 60 * 60 * 1000  // 1 year
})

export default useContractCharacteristics;