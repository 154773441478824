import React from 'react';
import { Form, Slider, Input } from 'antd';
import { subslate } from "subslate";

import config from '../../../../config.json';

const SliderNumberInput = ({ value, onChange, placeHolder }) => {

    const handleChange = ({ target }) => {
        console.log("Change...");
		const newValue = target.value.trim();

        // If first char is -
        if(newValue.length === 1 && newValue[0] === "-") {
            onChange(newValue);
            return;
        }

        const newNumber = Number(newValue);

		if (Number.isNaN(newNumber)) return;
        
        // If has decimal point
        if(newValue !== "" && !/^[+-]?\d+$/.test(newValue)) return;

        onChange(newValue);
	};

    return <Input type="text" className="input-number" value={value} placeholder={placeHolder} onChange={handleChange} />
}

const SliderItem = ({ characteristicId, name, label, rango, required, text, datason, disabled }) => {
    const range = rango[0];

    const [value, setValue] = React.useState(text);

    const min = range.valueTo ? range.valueFrom : 0;
    const max = range.valueTo ? range.valueTo : range.valueFrom;

    const placeHolder = React.useMemo(() => 
        range.valueTo ? `${range.valueFrom} a ${range.valueTo}` : `Max. ${range.valueFrom}`
    , [range]);

    const marks = {};
    marks[min] = min; 
    marks[max] = max;

    const validator = (_, value) => {
        // Required
		if(required && (value == null || value === ""))
            return Promise.reject(new Error(subslate(config.antd.form.validateMessages.required, { label })));

        if(!value || !range) return Promise.resolve();

        // Range
        const number = Number(value);

        // Only valueTo
        if(!range.valueTo){
            return number > range.valueFrom
                ? Promise.reject(new Error(subslate(config.forms.validationMessages.max, { max: range.valueFrom })))
                : Promise.resolve();
        }

        return number < range.valueFrom || number > range.valueTo
            ? Promise.reject(new Error(subslate(config.forms.validationMessages.range, { from: range.valueFrom, to: range.valueTo })))
            : Promise.resolve();
	};

    const handleChange = (valor) =>{
        setValue(valor);
        datason({
            id: characteristicId,
            valor: valor && valor.toString()
        }, false);
    };

    return <Form.Item name={name} label={label} rules={[{ validator, required }]} initialValue={text} >
        <span>
            <Slider marks={marks} min={min} max={max} onChange={handleChange} value={value} disabled={disabled} />
            <SliderNumberInput placeHolder={placeHolder} value={value} onChange={handleChange} />
        </span>
    </Form.Item>
}

export default SliderItem;